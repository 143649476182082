<template>
  <v-card class="ma-0" :maxHeight="height" :minHeight="minHeight">
    <v-card-title class="error white--text py-0" @click="fitchData"> Просроченные задачи ({{ data.length }}) </v-card-title>
    <v-divider />
    <v-card-text class="mt-0 pa-1 pt-0">
      <v-simple-table v-if="data.length > 0" fixed-header dense :height="height - 36" ref="tableLate">
        <template v-slot:default>
          <thead class="main-table-header">
            <tr>
              <th class="text-left px-1"></th>
              <th class="text-left px-1">Дэдлайн</th>
              <th class="text-left px-1">Заголовок</th>
              <th class="text-left px-1">Стасус</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(el, i) in data.slice(0, itemsPerPage)" :key="i" @click="$emit('click', el)" style="cursor: pointer">
              <td class="text-left px-1">
                <a-view-table-chip
                  :value="el.is_important"
                  :model="{
                    hideText: true,
                    showText: el.id,
                    options: $store.getters['config/get'].models.tasks.optionsIsImportant,
                  }"
                />
              </td>
              <td class="text-left px-1">
                <a-view-table-date :value="el.date_end" />
              </td>
              <td class="text-left px-1">
                <a-view-table-taskname :value="el.name" :values="el" />
              </td>
              <td class="text-left px-1">
                <a-view-table-select
                  :value="el.status"
                  :model="{
                    options: $store.getters['config/get'].models.tasks.statuses,
                  }"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else>Список пуст</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    Widget: () => import("./widgets/sqlTable"),
  },
  props: {
    height: { type: Number, default: 0 },
    rows: Array,
  },
  data() {
    return {
      itemsPerPage: 50,
      showTaskDialog: false,
      contentHeight: 10,
      idEdit: 0,
      loading: false,
      data: [],
      model: [
        { name: "id", hidden: true },
        {
          name: "is_important",
          type: "chip",
          hideText: true,
          showText: { field: "id" },
          options: this.$store.getters["config/get"].models.tasks.optionsIsImportant,
          width: 50,
        },
        { name: "date_end", title: "Дэдлайн", type: "date", width: 80, sortable: true },
        { name: "name", title: "Заголовок", type: "string", width: 250 },
        { name: "status", title: "Статус", type: "select", options: this.$store.getters["config/get"].models.tasks.statuses },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll, true);
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll, true);
  },
  created() {
    this.fitchData();
  },
  computed: {
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
    filters() {
      const status = this.$store.getters["config/get"].models.tasks.statuses
        .filter((el) => {
          return !["ready", "cancel"].includes(el?.status);
        })
        .map((s) => {
          return s.value;
        });
      const f = this.$store.getters["config/get"].config.taskToday;
      let res = Object.assign(
        f.late.filters,
        {
          status,
        },
        {
          date_end: {
            condition: "<",
            value: new Date().date,
          },
        }
      );
      return res;
    },
  },
  methods: {
    onScroll(e) {
      if (!this.data.length) return;
      let el = this.$refs.tableLate;
      let offset = 200;
      el = el.$el.querySelector(".v-data-table__wrapper");
      if (e.target == el) {
        if (el.scrollHeight - el.offsetHeight <= el.scrollTop + offset) {
          this.itemsPerPage += this.itemsPerPage < this.data.length - 50 ? 50 : this.data.length - this.itemsPerPage;

          //  this.$emit("scrollEnd", e);
        }
      }
    },
    async fitchData() {
      if (this.rows && false) {
        this.data = this.rows.multiSort(["-id"]);
        return;
      }
      this.loading = true;
      let d = await this.$axios.post("/report_sql", { name: "getAlertTask", params: { user_id: this.$root.profile.id } });
      let list = [];
      if (d.data.data.length) {
        list = d.data.data.map((el) => {
          return el.id;
        });
      }
      this.data = (
        await this.$axios.get("/mechti/tasks", {
          params: {
            sort: { key: "id", order: "DESC" },
            filters: { id: [-1, ...list] },
          },
        })
      ).data.data;
      /* let data = await this.$axios.get("/mechti/tasks-buying", {
        params: {
          sort1: { key: "date_start", order: "ASC" },
          sort: { key: "id", order: "DESC" },
          filters: this.filters,
        },
      }); */
      //      console.log("data-goods", data);
      //      this.data = [...this.data, ...data.data.data];
      this.loading = false;
    },
  },
};
</script>
